type AnyDialogIsShowRecordType = Record<string, boolean>

type AnyDialogIsShowRecordUpdater = (name: string, value: boolean) => void

const initialValue: AnyDialogIsShowRecordType = {}

const context = ref(initialValue)

export const useAnyDialogIsShowRecord = () => {
  const update: AnyDialogIsShowRecordUpdater = (name, value) => {
    context.value = {
      ...context.value,
      [name]: value,
    }
  }
  return {
    context,
    update,
  }
}
